
  import Vue from 'vue';

  export default Vue.extend({
    name: 'es-faq-list',

    props: {
      list: {
        type: Object,
        required: true,
      },
    },
  });
